
import { defineComponent, computed, reactive, watch } from 'vue'
import { useStore } from 'vuex'
import { useRouter, useRoute } from 'vue-router'
import CloserController from '@/components/molecules/CloserController.vue'
import AppButton from '@/components/atoms/AppButton.vue'
import { useI18n } from 'vue-i18n'
import AppCheckbox from '@/components/atoms/AppCheckbox.vue'
import FacetPanelTree from '@/components/organisms/FacetPanelTree.vue'
import { Bucket } from '@/data/@types/Aggregations'
import NdcDivisions from '@/data/master/NdcDivisions'
import Languages from '@/data/master/Languages'
import { getLabelWithCode } from '@/data/master/MasterUtil'
import toLocale from '@/helpers/util/toLocale'

type State = {
  ariaSelected: boolean;
  unitNum: number;
};
export default defineComponent({
  name: 'FacetPanel',
  components: {
    CloserController,
    AppButton,
    AppCheckbox,
    FacetPanelTree,
  },
  props: {
    facetItems: {
      type: Object,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
  },
  setup (props) {
    const route = useRoute()
    const router = useRouter()
    const store = useStore()
    const lang = useI18n().locale
    const i18n = useI18n()

    const to = computed(() => ({
      query: route.query,
    }))

    const hasShowMoreButton = ['rekionGenre', 'articleType', 'institutionLabel', 'subjectLabel', 'languageLabel', 'collectionBasis', 'ghqLabel', 'classicLabel', 'sessionLabel'].includes(props.name)
    const showFacetArea = computed(() => props.facetItems.some((bucket: Bucket) => bucket.docCount))

    const state = reactive<State>({
      ariaSelected: true,
      unitNum: 1,
    })

    const query = reactive({
      publicationFrom: '',
      publicationTo: '',
    })

    const unit = 10
    const isShowingAllFacet = computed(() => props.facetItems.length <= unit * state.unitNum)
    /**
     * ファセット項目の表示サイズを切り替える
     */
    const changeDisplaySize = () => {
      if (isShowingAllFacet.value) {
        state.unitNum = 1
      } else {
        state.unitNum++
      }
    }

    const composeRouterLink = (facetItem: any) => {
      const paramName = store.getters.FacetNameQueryMap[props.name]
      const where = {
        ...to.value.query,
        ...facetItem.query,
        pageNum: '0',
      }
      // 選択済みだった場合は逆に絞り込み解除する
      if (facetItem.isSelected) {
        if (isNotTreeFacetItem(props.name)) {
          where[paramName] = where[paramName].filter(
            (e: string) => e.toString() !== facetItem.keyAsString
          )
        } else {
          // 絞り込み解除時に親と子の要素含めて解除する
          // 親 + 自分 + 子のkeyを詰める配列
          let allLevelKeyList: Array<string> = []
          // ツリー構造のファセット項目
          switch (props.name) {
            case 'digitizedContents':
            case 'onlinePublications':
            case 'materialTypeLabel':
              allLevelKeyList = makeParentAndMeAndChildrenCollectionIdList(facetItem.key, props.facetItems as any)
              break
            case 'ndcDivisions':
              allLevelKeyList = makeParentAndMeAndChildrenNdcIdList(facetItem.key, Array.from<Bucket>(store.getters.facetList[props.name].buckets))
              break
          }

          where[paramName] = where[paramName].filter(
            (e: string) => !allLevelKeyList.includes(e)
          )
        }
      }
      // 簡易検索・詳細検索・コレクションでnameがかわるのでqueryだけ指定する
      return { query: where }
    }

    /**
     * 選択したNDC分類とその下位層に位置するNDC分類（表示対象のみ）のkeyリストを作る
     */
    const makeMeAndChildrenNdcKeyList = (facetItemKey: string, buckets: Array<Bucket>) => {
      const childrenNdcKeyList: Array<string> = []
      buckets.forEach((bucket: Bucket) => {
        // 選択したファセットkeyと同じ桁数分、先頭から引き抜いた値
        // 例）facetItemKey: 1 bucket.key: 123 ndcKeyPrefix: 1
        // 例）facetItemKey: 12 bucket.key: 123 ndcKeyPrefix: 12
        const ndcKeyPrefix = bucket.key.slice(0, facetItemKey.length)
        if (facetItemKey === ndcKeyPrefix) childrenNdcKeyList.push(bucket.key)
      })
      return childrenNdcKeyList
    }

    /**
     * ファセット（NDC分類のみ）で指定したkeyを元に、「親 + 自分 + 子」のIDの配列を返す
     */
    const makeParentAndMeAndChildrenNdcIdList = (facetItemKey: string, buckets: Array<Bucket>) => {
      const familyNdcKeyList: Array<string> = []
      // 自身（選択したkey）を追加する
      familyNdcKeyList.push(facetItemKey)
      // 子を追加する
      makeMeAndChildrenNdcKeyList(facetItemKey, buckets).forEach((key: string) => familyNdcKeyList.push(key))
      // 選択したkeyが親を持つ場合には親も追加する
      if (facetItemKey.length !== 1) {
        buckets.forEach((bucket: Bucket) => {
          for (let i = 1; i < facetItemKey.length; i++) {
            const ndcKeyPrefix = facetItemKey.slice(0, i)
            if (bucket.key === ndcKeyPrefix) familyNdcKeyList.push(bucket.key)
          }
        })
      }
      return familyNdcKeyList
    }

    const convertFlatBuckets = (treeBuckets: Array<any>): Bucket[] => {
      const pickupBuckets = (b: Bucket): Bucket[] => {
        const flatBuckets: Bucket[] = []
        // eslint-disable-next-line no-unused-expressions
        b?.children?.map(c => pickupBuckets(c))?.flat()?.forEach(c => flatBuckets.push(c))
        flatBuckets.push(b)
        return flatBuckets
      }
      return treeBuckets?.map((b: Bucket) => pickupBuckets(b))?.flat()
    }

    /**
     * ファセット（コレクションのみ）で指定したkeyを元に、「親 + 自分 + 子」のIDの配列を返す
     */
    const makeParentAndMeAndChildrenCollectionIdList = (facetItemKey: string, treeBuckets: Array<any>) => {
      //  処理がしやすい様に,階層構造になっているBucket情報を親子関係を保持した状態で、フラット構造に変換
      const flatBuckets = convertFlatBuckets(treeBuckets)

      const parentCollectionIdList: Array<string> = []
      // 対象の子を加える
      const targetBucketList = flatBuckets?.find((bucket: Bucket) => bucket.key === facetItemKey)
      // 対象のkeyの持つ子のIDを追加する
      const addChildrenId = (targetBucketList: Array<Bucket>, familyCollectionIdList: Array<string>) => {
        targetBucketList.forEach((bucket: Bucket) => {
          familyCollectionIdList.push(bucket.key)
          if (bucket.children) addChildrenId(bucket.children, familyCollectionIdList)
        })
      }

      // 自分 + 親のみのID配列を作成
      const makeParentAndMeCollectionIdList = (bucketChildren: Array<any>, parentCollectionIdList: Array<string>, targetFacetItemKey: string) => {
        for (const child of bucketChildren) {
          parentCollectionIdList.push(child.key)
          if (child.children && child.key !== targetFacetItemKey) {
            makeParentAndMeCollectionIdList(child.children, parentCollectionIdList, targetFacetItemKey)
          } else {
            if (child.key === targetFacetItemKey) {
            } else {
              parentCollectionIdList.splice(parentCollectionIdList.length - 1, 1)
              continue
            }
          }
          if (parentCollectionIdList[parentCollectionIdList.length - 1] === targetFacetItemKey) {
            break
          } else {
            parentCollectionIdList.splice(parentCollectionIdList.length - 1, 1)
          }
        }
      }
      makeParentAndMeCollectionIdList(treeBuckets, parentCollectionIdList, facetItemKey)
      if (targetBucketList?.children) addChildrenId(targetBucketList.children, parentCollectionIdList)
      return parentCollectionIdList
    }

    const search = (facetItem: any) => {
      router.push(composeRouterLink(facetItem))
      store.commit('UPDATE_SEARCH_STATE', false)
    }

    // チェックされた項目を格納
    const checkedFacet = reactive<Array<string>>([])

    // れきおん：クエリからファセットの選択状態をチェックボックスに反映する
    if (route.meta.isRekion) {
      watch(route, () => {
        checkedFacet.length = 0
        const facetQuery = route.query[store.getters.FacetNameQueryMap[props.name]]
        if (facetQuery && facetQuery.length) {
          if (typeof facetQuery !== 'string') {
            facetQuery.forEach((item: any) => {
              checkedFacet.push(item)
            })
          } else {
            checkedFacet.push(facetQuery)
          }
        }
      })
    }

    // デジデポ：クエリからファセットの選択状態をチェックボックスに反映する
    const facetQuery = route.query[store.getters.FacetNameQueryMap[props.name]]
    if (facetQuery) {
      if (typeof facetQuery !== 'string') {
        facetQuery.forEach((item: any) => {
          checkedFacet.push(item)
        })
      } else {
        checkedFacet.push(facetQuery)
      }
    }

    const isNotTreeFacetItem = (facetType: string): boolean => {
      switch (facetType) {
        case 'ndcDivisions':
        case 'digitizedContents':
        case 'onlinePublications':
        case 'materialTypeLabel':
          return false
        case 'accessRestrictions':
        case 'bibliographicLevel':
        case 'languageLabel':
        default:
          return true
      }
    }

    const collectionMaster = computed(() => store.getters.CollectionMaster)
    const accessRestrictionLabel = route.meta.isRekion ? store.getters.RekionAccessRestrictionLabel : store.getters.AccessRestrictionLabel

    // いずれかのファセット項目にチェックが入っているかのフラグ
    // ※Tree形式のファセット項目をグレーアウト表示考慮不要とすることで、チェック確認対象を軽減する目的で、
    //   isNotTreeFacetItem が true のファセット項目のみを対象とする。
    const isEitherOfFacetItemChecked = isNotTreeFacetItem(props.name) && props.facetItems.some((facetItem: Bucket) => facetItem.isSelected)

    // いずれのファセット項目にもチェックが入っていない かつ ファセット項目検索件数が0件である場合にのみ、グレーアウトで表示
    const isHiddenCheckBox = (facetItem: Bucket) => {
      return !isEitherOfFacetItemChecked && Number(facetItem.docCount) === 0
    }

    return {
      to,
      state,
      lang,
      query,
      collectionMaster,
      accessRestrictionLabel,
      search,
      getFacetTitle: (key: string) => {
        return i18n.t(`facetTitleMap.${key}`)
      },
      getFacetLabel: (key: string) => {
        switch (props.name) {
          case 'accessRestrictions':
            return route.meta.isRekion ? i18n.t(`facet.rekionAccessRestrictionLabel.${key}`) : i18n.t(`facet.accessRestrictionLabel.${key}`)
          case 'bibliographicLevel':
            return i18n.t(`facet.bibliographicLevelLabel.${key}`)
          case 'ndcDivisions':
            return getLabelWithCode(NdcDivisions, key, lang.value as 'ja' | 'en')
          case 'languageLabel':
            return getLabelWithCode(Languages, key, lang.value as 'ja' | 'en')
          case 'digitizedContents':
          case 'onlinePublications':
          case 'materialTypeLabel':
            return collectionMaster.value[key] ? collectionMaster.value[key].content.name?.[lang.value] : ''
          case 'itemToSearch':
            return i18n.t(`facet.itemToSearchLabel.${key}`)
          case 'sessionLabel':
            return i18n.t(`facet.sessionLabel.${key}`)
          case 'houseLabel':
            return i18n.t(`facet.houseLabel.${key}`)
          default:
            return key
        }
      },
      getFacetPanelId: (str: string) => {
        return (
          'facet-panel-' +
          str.replace(/[A-Z]/g, (letter) => `-${letter.toLowerCase()}`)
        )
      },
      composeRouterLink,
      isNotTreeFacetItem,
      checkedFacet,
      changeDisplaySize,
      hasShowMoreButton,
      unit,
      isShowingAllFacet,
      showFacetArea,
      toLocale,
      isHiddenCheckBox,
    }
  },
})
