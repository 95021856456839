import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withModifiers as _withModifiers, withKeys as _withKeys, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7300b54a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "level is-mobile flex-grow" }
const _hoisted_2 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppCheckbox = _resolveComponent("AppCheckbox")!
  const _component_FacetPanelRecursiveList = _resolveComponent("FacetPanelRecursiveList", true)!

  return (_openBlock(), _createElementBlock("li", {
    class: _normalizeClass(["facet-panel-recursive-list", `children-${_ctx.n}`])
  }, [
    _createVNode(_component_AppCheckbox, {
      modelValue: _ctx.checkedFacetKeys,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.checkedFacetKeys) = $event)),
      onClickOnce: _cache[1] || (_cache[1] = ($event: any) => (_ctx.search(_ctx.item))),
      onKeydownOnce: _cache[2] || (_cache[2] = _withKeys(_withModifiers(($event: any) => (_ctx.search(_ctx.item)), ["prevent"]), ["space","enter"])),
      value: _ctx.item.key,
      class: "facet-panel-recursive-list-body flex-grow has-text-normal",
      isChecked: _ctx.item.isSelected
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("span", null, _toDisplayString(_ctx.getFacetLabel(_ctx.item.key)), 1),
          _createElementVNode("span", null, _toDisplayString(_ctx.toLocale(_ctx.item.docCount, _ctx.lang)), 1)
        ])
      ]),
      _: 1
    }, 8, ["modelValue", "value", "isChecked"]),
    (_ctx.item.children)
      ? (_openBlock(), _createElementBlock("ul", _hoisted_2, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.item.children, (child) => {
            return (_openBlock(), _createBlock(_component_FacetPanelRecursiveList, {
              key: child,
              item: child,
              n: _ctx.n + 1,
              name: _ctx.name,
              checkedFacet: _ctx.checkedFacet,
              search: _ctx.search
            }, null, 8, ["item", "n", "name", "checkedFacet", "search"]))
          }), 128))
        ]))
      : _createCommentVNode("", true)
  ], 2))
}